import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

import Model from './Model'

dayjs.extend(utc)

export default class TrainingProgramDay extends Model {
  resource() {
    return 'training-program-days'
  }
}
