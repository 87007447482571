<template>
  <div class="new-program-workout-days">
    <!--    Header-->
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Workouts Days</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            @click="saveForLater"
            :loading="loading"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            depressed
            text
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!--    Content-->
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <training-program-workout-day
          v-bind="$attrs"
          :training-programs="trainingPrograms"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrainingProgramWorkoutDay from '@/components/forms/TrainingProgramWorkoutDay'
import { mdiArrowRight } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: 'ProgramWorkoutDays',
  components: {
    TrainingProgramWorkoutDay,
  },
  computed: {
    ...mapGetters({
      trainingPrograms: 'programs/getSelectedProgramTrainingPrograms',
      isCompleted: 'programs/isSelectedProgramComplete',
    }),
  },
  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      loading: false,
    }
  },
  methods: {
    saveForLater() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'completed.programs',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'draft.programs',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
    saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'program.checkins',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.program.checkins',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
  },
}
</script>
