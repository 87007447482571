<template>
  <div>
    <!--    Confirm Delete-->
    <confirm-dialog ref="confirmDelete" />
    <!--        Workout Day Form-->
    <v-dialog v-model="showWorkoutDayForm" max-width="650" persistent>
      <v-card>
        <v-card-title>Workout Days</v-card-title>
        <v-card-text>
          <v-select
            v-model="form.workout_days_count"
            :disabled="!!form.id"
            hide-selected
            :items="[1, 2, 3, 4, 5, 6]"
            label="No. of days"
            :error-messages="form.$getError('workout_days_count')"
            :hide-details="!form.$getError('workout_days_count')"
          ></v-select>
          <v-select
            v-model="form.workout_days"
            multiple
            chips
            small-chips
            :items="days"
            label="Days"
            :error-messages="form.$getError('workout_days')"
            :hide-details="!form.$getError('workout_days')"
          ></v-select>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn text @click="showWorkoutDayForm = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveWorkoutDay">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--        Training Programs-->
    <template v-if="trainingPrograms.length !== 0">
      <template v-if="isVenue">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <h4>{{ concatTrainingProgramNames }}</h4>
                <v-spacer />
                <v-btn icon>
                  <v-icon
                    color="primary"
                    @click="showWorkoutDayFormDialog(trainingPrograms[0].id)"
                    >{{ icons.add }}</v-icon
                  >
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-simple-table
                v-if="trainingPrograms[0].workout_days.length !== 0"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        No. of Workout Days
                      </th>
                      <th class="text-left" style="width: 70%">
                        Days
                      </th>
                      <th class="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="day in trainingPrograms[0].workout_days"
                      :key="day.id"
                    >
                      <td>
                        <strong>{{ day.workout_days_count }}</strong>
                      </td>
                      <td>
                        <v-chip
                          v-for="workoutDay in day.workout_days"
                          :key="workoutDay"
                          class="mr-1"
                          small
                          color="primary"
                          >{{ days[workoutDay].text }}</v-chip
                        >
                      </td>
                      <td class="text-center">
                        <v-icon
                          small
                          class="mr-2"
                          @click="
                            showWorkoutDayFormDialog(
                              trainingPrograms[0].id,
                              day
                            )
                          "
                        >
                          {{ icons.pencil }}
                        </v-icon>
                        <v-icon small @click="deleteWorkoutDay(day)">
                          {{ icons.trash }}
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <p v-else class="text-center pa-3">
                No Data
              </p>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-data-iterator
        v-else
        :items="trainingPrograms"
        item-key="name"
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col v-for="item in items" :key="item.id" cols="12">
              <v-card>
                <v-card-title>
                  <h4>{{ item.name }}</h4>
                  <v-spacer />
                  <v-btn icon>
                    <v-icon
                      color="primary"
                      @click="showWorkoutDayFormDialog(item.id)"
                      >{{ icons.add }}</v-icon
                    >
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-simple-table v-if="item.workout_days.length !== 0">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          No. of Workout Days
                        </th>
                        <th class="text-left" style="width: 70%">
                          Days
                        </th>
                        <th class="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="day in item.workout_days" :key="day.id">
                        <td>
                          <strong>{{ day.workout_days_count }}</strong>
                        </td>
                        <td>
                          <v-chip
                            v-for="workoutDay in day.workout_days"
                            :key="workoutDay"
                            class="mr-1"
                            small
                            color="primary"
                            >{{ days[workoutDay].text }}</v-chip
                          >
                        </td>
                        <td class="text-center">
                          <v-icon
                            small
                            class="mr-2"
                            @click="showWorkoutDayFormDialog(item.id, day)"
                          >
                            {{ icons.pencil }}
                          </v-icon>
                          <v-icon small @click="deleteWorkoutDay(day)">
                            {{ icons.trash }}
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <p v-else class="text-center pa-3">
                  No Data
                </p>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
  </div>
</template>

<script>
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js'
import TrainingProgramDay from '@/models/TrainingProgramDay'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Form from '@/utils/form'

const formData = {
  id: null,
  program_id: null,
  training_program_id: null,
  workout_days_count: null,
  workout_days: [],
  created_at: null,
  updated_at: null,
}

export default {
  name: 'TrainingProgramWorkoutDay',
  components: {
    ConfirmDialog,
  },
  props: {
    trainingPrograms: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        add: mdiPlus,
        pencil: mdiPencil,
        trash: mdiDelete,
      },
      days: [
        { value: 0, text: 'Sunday' },
        { value: 1, text: 'Monday' },
        { value: 2, text: 'Tuesday' },
        { value: 3, text: 'Wednesday' },
        { value: 4, text: 'Thursday' },
        { value: 5, text: 'Friday' },
        { value: 6, text: 'Saturday' },
      ],
      showWorkoutDayForm: false,
      form: new Form(formData),
    }
  },
  computed: {
    concatTrainingProgramNames() {
      return this.trainingPrograms.reduce(
        (previousValue, currentValue, currentIndex) =>
          (previousValue += `${currentIndex !== 0 ? '/' : ''}${
            currentValue.name
          }`),
        ''
      )
    },
    isVenue() {
      return this.trainingPrograms.every(
        (trainingProgram) => trainingProgram.workout_type_id === 1
      )
    },
  },
  methods: {
    generateEndPoints(operation) {
      const endpoints = []

      if (!this.isVenue) {
        endpoints.push(new TrainingProgramDay(this.form)[operation]())
      } else {
        for (const trainingProgram of this.trainingPrograms) {
          const data = { ...this.form }
          data.training_program_id = trainingProgram.id

          if (this.form.id) {
            data.id = trainingProgram.workout_days.find(
              (wd) => wd.workout_days_count === this.form.workout_days_count
            ).id
          }
          endpoints.push(new TrainingProgramDay(data)[operation]())
        }
      }

      return endpoints
    },
    async saveWorkoutDay() {
      this.form.$busy = true
      this.form.$clearErrors()
      const endpoints = this.generateEndPoints('save')

      try {
        // insert/update DB
        const data = await Promise.all(endpoints)
        for (const datum of data) {
          // insert/update UI
          const trainingProgram = this.trainingPrograms.find(
            (tp) => tp.id === datum.training_program_id
          )

          if (!this.form.id) {
            trainingProgram.workout_days.push(datum)
          } else {
            let workoutDay = trainingProgram.workout_days.find(
              (day) => day.id === datum.id
            )
            Object.assign(workoutDay, datum)
          }
        }
        this.showWorkoutDayForm = false
      } catch ({ response }) {
        this.form.$timeout(() => {
          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
        })
      }
      this.form.$busy = false
    },
    async deleteWorkoutDay(workoutDay) {
      this.form = new Form(workoutDay)

      const confirm = await this.$refs.confirmDelete.open(
        'Remove Workout Day',
        'Are you sure you want to remove this workout day?'
      )

      if (!confirm) return
      try {
        const endpoints = this.generateEndPoints('delete')

        await Promise.all(endpoints)

        const trainingProgram = this.trainingPrograms.find(
          (tp) => tp.id === workoutDay.training_program_id
        )
        trainingProgram.workout_days = trainingProgram.workout_days.filter(
          (day) => day.id !== workoutDay.id
        )
      } catch (err) {
        throw new Error('Deletion of workout day failed.')
      }
    },
    showWorkoutDayFormDialog(trainingProgramId, workoutDay) {
      // reset form
      this.form.$hardReset()

      // prepare data
      this.form.training_program_id = trainingProgramId
      this.form.program_id = this.$attrs.id

      if (workoutDay) this.form.$refresh(workoutDay)

      // display form
      this.showWorkoutDayForm = true
    },
  },
}
</script>

<style scoped></style>
